$(() => {
  if (!isCurrentView('.sbsa-signup.product_selection')) { return; }
  const submitButton = document.getElementById('submit-button');

  // Payment needs
  const hasInPerson = document.getElementById('omj_shopify_order_form_in_person').value;

  // Card terminal card
  const learnMoreCardTerminal = document.getElementById('learn_more_card_terminal');
  const cardTerminalContainer = document.getElementById('card_terminal_container');
  const cardTerminalQuantityContainer = document.getElementById('card_terminal_quantity_container');

  // Card terminal checkbox
  const cardTerminalCheckbox = document.getElementById("omj_shopify_order_form_card_terminal");
  const orderQuantity = document.getElementById("omj_shopify_order_form_quantity");
  const orderMaxField = document.getElementById('omj_shopify_order_form_max_available');

  // Card terminal stepper
  const stepperAdd = document.getElementById("stepperAdd");
  const stepperRemove = document.getElementById("stepperRemove");

  // If it doesn't find te field assign default 4
  const max = orderMaxField ? orderMaxField.value : 4;
  stepperIncrementListener(stepperAdd, orderQuantity, 1, max, stepperRemove, stepperAdd);
  stepperDecrementListener(stepperRemove, orderQuantity, 1, max, stepperRemove, stepperAdd);

  addCheckboxEventListeners();

  if (orderQuantity) {
    orderQuantity.value == 0
      ? cardTerminalCheckbox.checked = false
      : cardTerminalCheckbox.checked = true;
      applyStepperClass(1, max, orderQuantity, stepperRemove, stepperAdd);
  }

  if (cardTerminalCheckbox) {
    if (cardTerminalCheckbox.checked) {
      toggleDisplay(cardTerminalQuantityContainer);
    }

    cardTerminalCheckbox.addEventListener('click', () => {
      cardTerminalCheckbox.checked ? false : true;
      orderQuantity.value = cardTerminalCheckbox.checked ? 1 : 0;
      toggleDisplay(cardTerminalQuantityContainer);

      applyStepperClass(1, max, orderQuantity, stepperRemove, stepperAdd);
    });
  }

  if (stepperRemove) {
    stepperRemove.addEventListener('click', () => {
      if (orderQuantity && orderQuantity.value == 0) {
        cardTerminalCheckbox.checked = false;
        toggleDisplay(cardTerminalQuantityContainer);
      }
    });
  }

  if (stepperAdd) {
    stepperAdd.addEventListener('click', () => {
      if (orderQuantity && orderQuantity.value > 0) {
        cardTerminalCheckbox.checked = true;
      }
    });
  }

  //Add initialize functions on page init
  initializePaymentOptions();
  enableSubmit();

  // Learn more expand
  if (learnMoreCardTerminal) {
    learnMoreCardTerminal.addEventListener('click', () => {
      showMore(cardTerminalContainer, learnMoreCardTerminal);
    });
  }

  function showMore(container, learnMore) {
    container.classList.toggle('animate-learn-more');

    const hasLearnMoreOpen = container.classList.contains('animate-learn-more');
    learnMore.textContent = hasLearnMoreOpen ? "Show less" : "Learn more"
  }

  function toggleDisplay(container) {
    if (!container) { return; }

    container.classList.toggle('animate');
  }

  function show(container) {
    if (!container) { return; }
    container.classList.remove('tw-hidden');
    container.classList.add('tw-flex');
  }

  function initializePaymentOptions() {
    if (hasInPerson === "true") {
      show(cardOption);
    }
  }

  function enableSubmit() {
    submitButton.disabled = cardTerminalCheckbox && cardTerminalCheckbox.checked ? false : true;
  }

  function addCheckboxEventListeners() {
    enableSubmit();

    if (cardTerminalCheckbox) {
      cardTerminalCheckbox.addEventListener('change', () => {
        enableSubmit();
      });
    }
  }
});
