/*
  You can only include files that actually call functions like views.
  If you want to to create shared functions you need to export the functions and import them within the JS file
  example for export: export function name
  example for import all: import * as EXAMPLE_NAME from '';
  example for import individual: import { functionName } from 'location/of/file';
*/

// View like imports
import "packs/shared/password-indicator.js";
import "packs/shared/address-autofill.js";
import "packs/shared/sidebar.js";
