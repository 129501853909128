import { initRelatedPartyAutocomplete } from '../../../shared/utils/address-autofill-utils';

function toggleConditionalFields(shouldAnimate, isValid, select, input, conditionalField) {
  const fieldConditional = $(`${conditionalField}`);
  const selectElement = $(`${select}`);
  const selectedOption = $(`${select} option:selected`);
  const inputField = $(`${input}`);

  animateElement(shouldAnimate, fieldConditional, isValid);

  if (!isValid) {
    if (select) {
      selectElement.index(0);
      selectedOption.prop('selected', false);
    }

    if (input) {
      inputField.val('');
    }
  }
}

function initializePublicOfficialChangeDetection() {
  // Public official toggle conditional fields
  const radiosPipRelative = $('input[type=radio][name="related_party_form[public_official_relative]"]');
  const radioChecked = '#related_party_form_public_official_relative_true:checked';

  toggleConditionalFields(
    false,
    $(`${radioChecked}`).val(),
    '#related_party_form_public_official_relative_relationship',
    '#related_party_form_public_official_relative_full_name',
    '.conditional-field'
  );

  radiosPipRelative.on('change', () => {
    toggleConditionalFields(
      true,
      $(`${radioChecked}`).val(),
      '#related_party_form_public_official_relative_relationship',
      '#related_party_form_public_official_relative_full_name',
      '.conditional-field'
    );
  });
}

function initializeNationalityChangeDetection() {
  // Nationality toggle conditional field
  const radiosNationalityRelative = $('input[type=radio][name="related_party_form[nationality_equivalent]"]');
  const radioChecked = '#related_party_form_nationality_equivalent_false:checked';

  toggleConditionalFields(
    false,
    $(`${radioChecked}`).val(),
    '#related_party_form_country_of_birth',
    null,
    '.conditional-nationality'
  );

  radiosNationalityRelative.on('change', () => {
    toggleConditionalFields(
      true,
      $(`${radioChecked}`).val(),
      '#related_party_form_country_of_birth',
      null,
      '.conditional-nationality'
    );
  });
}

function toggleLegalShareholderAddress(shouldAnimate, isLegalShareholder) {
  const addressElement = $('#legal-shareholder-address');
  animateElement(shouldAnimate, addressElement, isLegalShareholder);
}

function toggleShareholderDocuments(shouldAnimate, isLegalShareholder) {
  const idDocumentElement = $('#related_party_form_id_document_file_section');
  const cipcDocumentElement = $('#related_party_form_cipc_registration_certificate_file_section');

  animateElement(shouldAnimate, idDocumentElement, !isLegalShareholder);
  animateElement(shouldAnimate, cipcDocumentElement, isLegalShareholder);
}

function initializePartyTypeChangeDetection() {
  const partyType = '#related_party_form_party_type'
  const partyTypeElement = $(`${partyType}`);
  const isLegalShareholder = partyTypeElement.val() === 'shareholder_legal_entity';

  toggleShareholderDocuments(false, isLegalShareholder);

  toggleLegalShareholderAddress(false, isLegalShareholder);

  toggleInputLabels();

  toggleConditionalFields(
    false,
    !isLegalShareholder,
    null,
    null,
    '.conditional-party-type'
  );

  $(`${partyType}`).change(function () {
    const isShareholder = partyTypeElement.val() === 'shareholder';

    if (!isShareholder) {
      $("#related_party_form_nationality_equivalent_true").prop("checked", true).change();
    }

    toggleShareholderDocuments(true, !isShareholder);

    toggleLegalShareholderAddress(true, !isShareholder);

    toggleInputLabels();

    toggleConditionalFields(
      true,
      isShareholder,
      null,
      null,
      '.conditional-party-type'
    );
  });
}

function updateRequiredShareholderQueryParam(paramValue) {
  $('.require-shareholder-href').each(function () {
    let oldUrl = $(this).attr('href'); // Retrieve current href
    let newUrl = replaceUrlParam(false, oldUrl, 'require_shareholder', paramValue);
    $(this).attr('href', newUrl); // Set new href
  });
}

// Show the related parties container once shareholders_required has a value
function showRelatedPartiesContainer(shouldAnimate, SHAREHOLDER_INFO_REQUIRED_SUB_TYPE) {
  const relatedPartiesContainer = $('#related-parties-container');
  const merchantType = $('#related_parties_overview_form_sub_type_of_merchant').val();
  const radioShareholderRequiredChecked = $('#related_parties_overview_form_shareholders_required_true:checked');
  const radioShareholderRequiredUnchecked = $('#related_parties_overview_form_shareholders_required_false:checked');

  if (
    merchantType === SHAREHOLDER_INFO_REQUIRED_SUB_TYPE &&
    radioShareholderRequiredChecked.val() === undefined &&
    radioShareholderRequiredUnchecked.val() === undefined
  ) {
    relatedPartiesContainer.hide();
  } else {
    animateElement(shouldAnimate, relatedPartiesContainer, true);
  }
}

function checkShareholderRequiredVal(shouldAnimate, SHAREHOLDER_INFO_REQUIRED_SUB_TYPE) {
  const radioShareholderRequiredChecked = $('#related_parties_overview_form_shareholders_required_true:checked');
  const shareholderInfo = $('#shareholder-info');
  const merchantType = $('#related_parties_overview_form_sub_type_of_merchant').val();
  const isRequired = radioShareholderRequiredChecked.val() === 'true' && merchantType === SHAREHOLDER_INFO_REQUIRED_SUB_TYPE;

  isRequired ? updateRequiredShareholderQueryParam('true') : updateRequiredShareholderQueryParam('false');
  animateElement(shouldAnimate, shareholderInfo, isRequired);
};

function add_file_block(element, event) {
  const files = event.target.files;
  const id = $(element).attr("id");
  const container = $(`#${id}_file_grid`);
  container.children().hide();

  $.each(files, function (key, value) {
    container.append(
      $("#document_uploader_template")
        .html()
        .replace("#DOC_NAME#", value.name)
    ).fadeIn(300);
    $('.delete_file').on("click", function () {
      $(this).closest(container).fadeOut(300, function () {
        $(this).children().remove();
      });
    });
  });
}

function updatePlaceholderAndError(isSelect, isRequired, shouldToggleRequired, selector, label, previousLabel) {
  const elementBlock = document.getElementsByClassName(selector);

  if (isSelect) {
    $(`#${selector} option:first`).text(
      isRequired ? `${label} *` : `${label}`
    ).change();
  }

  if (elementBlock &&
    elementBlock[0] &&
    elementBlock[0].children
  ) {
    if (elementBlock[0].children[1] && label) {
      elementBlock[0].children[1].innerHTML = isRequired ? `${label} <abbr title="required">*</abbr>` : `${label}`;
    }

    if (shouldToggleRequired && elementBlock[0].children[1] && elementBlock[0].children[2]) {
      isRequired
        ? elementBlock[0].children[0].classList.add("is-invalid")
        : elementBlock[0].children[0].classList.remove("is-invalid");
    }

    if (elementBlock[0].children[2]) {
      elementBlock[0].children[2].innerHTML = elementBlock[0].children[2].innerHTML.replace(previousLabel, label)
    }
  }
}

function updateFullNameField(isSelect, isRequired, shouldToggleRequired, selector, label, previousLabel) {
  updateInputLabelDisplay(
    "label[for='related_party_form_full_name']",
    '#related_party_form_full_name',
    label
  );

  updatePlaceholderAndError(
    isSelect,
    isRequired,
    shouldToggleRequired,
    selector,
    label,
    previousLabel
  );
}

function updateIdentificationField(isSelect, isRequired, shouldToggleRequired, selector, label, previousLabel) {
  updateInputLabelDisplay(
    "label[for='related_party_form_identification']",
    '#related_party_form_identification',
    label
  );

  updatePlaceholderAndError(
    isSelect,
    isRequired,
    shouldToggleRequired,
    selector,
    label,
    previousLabel
  );
}

function updateCountryOfResidenceField(isSelect, isRequired, shouldToggleRequired, selector, label, previousLabel) {
  updateInputLabelDisplay(
    "label[for='country_of_residence']",
    '#related_party_form_country_of_residence',
    label
  );

  updatePlaceholderAndError(
    isSelect,
    isRequired,
    shouldToggleRequired,
    selector,
    label,
    previousLabel
  );
}

function toggleInputLabels() {
  const type = $('#related_party_form_party_type :selected').val();

  if (!type) { return }

  if (type === 'shareholder') {
    updateFullNameField(
      false,
      true,
      false,
      'related_party_form_full_name',
      'Name and surname',
      'Business name'
    );

    updateIdentificationField(
      false,
      true,
      true,
      'related_party_form_identification',
      'ID/Passport number',
      'Business registration number'
    );

    updateCountryOfResidenceField(
      true,
      true,
      false,
      'related_party_form_country_of_residence',
      'Country of residence',
      'Country of incorporation'
    );
  } else {
    updateFullNameField(
      false,
      true,
      false,
      'related_party_form_full_name',
      'Business name',
      'Name and surname'
    );

    updateIdentificationField(
      false,
      false,
      true,
      'related_party_form_identification',
      'Business registration number',
      'ID/Passport number'
    );

    updateCountryOfResidenceField(
      true,
      true,
      false,
      'related_party_form_country_of_residence',
      'Country of incorporation',
      'Country of residence'
    );
  }
}

$(() => {
  if (!isCurrentView('.sbsa-portal.related_parties.index')) { return; }

  const SHAREHOLDER_INFO_REQUIRED_SUB_TYPE = document.getElementById('new_related_parties_overview_form') && document.getElementById('new_related_parties_overview_form').getAttribute('data-entityType');

  checkShareholderRequiredVal(false, SHAREHOLDER_INFO_REQUIRED_SUB_TYPE);
  showRelatedPartiesContainer(false, SHAREHOLDER_INFO_REQUIRED_SUB_TYPE);

  $('input[type=radio][name="related_parties_overview_form[shareholders_required]"]').on('change', () => {
    $('#shareholders-required-text').removeClass("text-danger");
    checkShareholderRequiredVal(true, SHAREHOLDER_INFO_REQUIRED_SUB_TYPE);
    showRelatedPartiesContainer(true, SHAREHOLDER_INFO_REQUIRED_SUB_TYPE);
  });
});

$('.sbsa-portal.related_parties').ready(() => {
  if (!hasCurrentView(['.sbsa-portal.related_parties.new', '.sbsa-portal.related_parties.edit', ".sbsa-portal.related_parties.create", ".sbsa-portal.related_parties.update"])) { return; }

  // Address fields
  const address1Field = document.getElementById("address1");
  const address2Field = document.getElementById("address2");
  const postalCodeField = document.getElementById("postcode");
  const suburbField = document.getElementById("suburb");
  const cityField = document.getElementById("city");

  initializePublicOfficialChangeDetection();

  initializeNationalityChangeDetection();

  initRelatedPartyAutocomplete(address1Field, address2Field, postalCodeField, suburbField, cityField);

  const partyType = $("#related_party_form_party_type").val();

  // Update the label required display state
  if (partyType === 'legal_entity') {
    updateIdentificationField(
      false,
      false,
      true,
      'related_party_form_identification',
      'Registration number',
      null
    );

  } else {
    updateIdentificationField(
      false,
      true,
      true,
      'related_party_form_identification',
      'ID/Passport number',
      null
    );
  }

  $('#related_party_form_phone_number').on('input', function (e) {
    // Remove white spaces as the user types or pastes
    if (e && e.target && e.target.value) {
      e.target.value = e.target.value.replace(/\s+/g, '');
    }
  });
});

$('.sbsa-portal.related_parties.shareholder').ready(() => {
  if (!isCurrentView('.sbsa-portal.related_parties.shareholder')) { return; }

  // Address fields
  const address1Field = document.getElementById("address1");
  const address2Field = document.getElementById("address2");
  const postalCodeField = document.getElementById("postcode");
  const suburbField = document.getElementById("suburb");
  const cityField = document.getElementById("city");

  initializePartyTypeChangeDetection();

  initializeNationalityChangeDetection();

  initRelatedPartyAutocomplete(address1Field, address2Field, postalCodeField, suburbField, cityField);
});
