$(() => {
  if (!isCurrentView('.sbsa-portal')) { return; }

  // sidebar mobile nav toggle
  $('[data-toggle="offcanvas"]').on('click', function () {
    $('[data-toggle="offcanvas"]').toggleClass('is-active');
    $('.offcanvas-collapse').toggleClass('open');
    $('.navbar-backdrop').toggleClass('show');
  })
});
