import { isCurrentView, hideAlertEventListener } from '../../../shared/utils/view-util';

$(() => {
  if (!isCurrentView('.sbsa-portal.business_details')) { return; }

  $('a.disabled').on("click", function (e) {
    e.preventDefault();
  });

  hideAlertEventListener('.close-warning', 'feedback-alert');
});
