// password strength indicator
// uses https://github.com/dropbox/zxcvbn
function checkPasswordStrength() {
  const password = document.getElementById('sbsa_user_password') || document.getElementById('user_password');
  const meter = document.getElementById('password-strength-indicator');

  if (!password || !meter) { return; }

  password.addEventListener("keyup", (event) => {
    const val = password.value;
    const result = zxcvbn(val);

    // add class to field
    meter.classList.add('password-input');

    // update strength amount
    meter.setAttribute('data-strength', result.score);
  });
}

$(() => {
  if (!isCurrentView('.sbsa-registrations, .sbsa-passwords, .sbsa-portal.personal_settings.change_password, .sbsa-portal.personal_settings.update_password, .sbsa-pos_invite')) { return; }

  checkPasswordStrength();
});
