import {
  isCurrentView,
  togglePassword,
  isPasswordFieldInvalid,
  updateCheckboxState,
  initializePasswordIconState,
  strip
} from '../../shared/utils/view-util';

function updateAcceptTerms(termsCheckbox, createAccountBtn) {
  if (termsCheckbox.checked) {
    createAccountBtn.classList.remove('tw-opacity-50');
    createAccountBtn.classList.remove('tw-pointer-events-none');
    createAccountBtn.disabled = false;
    termsCheckbox.setAttribute('value', true)
  } else {
    createAccountBtn.classList.add('tw-opacity-50');
    createAccountBtn.classList.add('tw-pointer-events-none');
    createAccountBtn.disabled = true;
    termsCheckbox.setAttribute('value', false)
  }
}

// Login JS
$(() => {
  if (!isCurrentView('.sbsa-sessions.new')) { return; }

  const userPassword = document.getElementById("sbsa_user_password") || document.getElementById('user_password');
  const userPasswordWrapper = document.getElementById("password_wrapper");

  togglePassword(userPassword, userPasswordWrapper);
});

// Signup JS
$(() => {
  if (!isCurrentView('.sbsa-registrations.new, .sbsa-registrations.create')) { return; }

  const partnershipModalKey = "partnership_modal_hidden";
  const modalHidden = sessionStorage.getItem(partnershipModalKey);

  const userPassword = document.getElementById("sbsa_user_password") || document.getElementById('user_password');
  const userPasswordWrapper = document.getElementById("password_wrapper");
  const isUserPasswordInvalid = isPasswordFieldInvalid(false, userPasswordWrapper);

  const mobileNumber = document.getElementById('sbsa_user_lead_attributes_contact_number');
  const termsCheckbox = document.getElementById('accept_terms');
  const createAccountBtn = document.getElementById('create_account');

  // Uncheck terms for initial state.
  updateCheckboxState(termsCheckbox, false);
  updateAcceptTerms(termsCheckbox, createAccountBtn);

  // Initial state
  initializePasswordIconState(userPassword, userPasswordWrapper, isUserPasswordInvalid);

  // Add event listeners
  togglePassword(userPassword, userPasswordWrapper, isUserPasswordInvalid);

  mobileNumber.addEventListener('input', function (e) {
    if (e && e.target && e.target.value) {
      e.target.value = strip(e.target.value);
    }
  });

  termsCheckbox.addEventListener('click', () => {
    updateCheckboxState(termsCheckbox, termsCheckbox.checked);
    updateAcceptTerms(termsCheckbox, createAccountBtn);
  });

  if (!modalHidden) {
    showPartnershipModal();
  }

  function showPartnershipModal() {
    const partnershipModal = new bootstrap.Modal(document.getElementById('snapscan_partnership_explainer'), {});

    partnershipModal.show();
    sessionStorage.setItem(partnershipModalKey, false);
  }
});

// Reset password
$(() => {
  if (!isCurrentView('.sbsa-passwords.edit')) { return; }

  const userPassword = document.getElementById("sbsa_user_password") || document.getElementById('user_password');
  const userPasswordWrapper = document.getElementById("password_wrapper");

  const userPasswordConfirmation = document.getElementById('sbsa_user_password_confirmation') || document.getElementById('user_password_confirmation');
  const passwordConfirmationWrapper = document.getElementById("password_confirmation_wrapper");

  const isUserPasswordInvalid = isPasswordFieldInvalid(true, userPasswordWrapper);
  const isConfirmPasswordInvalid = isPasswordFieldInvalid(true, passwordConfirmationWrapper);

  // Initial state
  initializePasswordIconState(userPassword, userPasswordWrapper, isUserPasswordInvalid);
  initializePasswordIconState(userPassword, passwordConfirmationWrapper, isConfirmPasswordInvalid);

  togglePassword(userPassword, userPasswordWrapper);
  togglePassword(userPasswordConfirmation, passwordConfirmationWrapper);
});

// Update password
$(() => {
  if (!isCurrentView('.sbsa-passwords.update')) { return; }

  const userPassword = document.getElementById("sbsa_user_password") || document.getElementById('user_password');
  const userPasswordWrapper = document.getElementById("password_wrapper");

  const userPasswordConfirmation = document.getElementById('sbsa_user_password_confirmation') || document.getElementById('user_password_confirmation');
  const passwordConfirmationWrapper = document.getElementById("password_confirmation_wrapper");

  togglePassword(userPassword, userPasswordWrapper);
  togglePassword(userPasswordConfirmation, passwordConfirmationWrapper);
});

// POS_INVITE Set password
$(() => {
  if (!isCurrentView('.sbsa-signup.pos_invite.pos_invite_set_password')) { return; }

  const userPassword = document.getElementById("sbsa_user_password") || document.getElementById('user_password');
  const userPasswordWrapper = document.getElementById("password_wrapper");

  const userPasswordConfirmation = document.getElementById('sbsa_user_password_confirmation') || document.getElementById('user_password_confirmation');
  const passwordConfirmationWrapper = document.getElementById("password_confirmation_wrapper");

  togglePassword(userPassword, userPasswordWrapper);
  togglePassword(userPasswordConfirmation, passwordConfirmationWrapper);
});
