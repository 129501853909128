$(() => {
  if (!isCurrentView('.sbsa-signup.business_location')) { return; }

  const addressContainer = document.getElementById("delivery-address-container");
  const use_business_address_true = document.getElementById("business_location_form_use_business_address_true");
  const use_business_address_false = document.getElementById("business_location_form_use_business_address_false");

  // Address fields
  const address1Field = document.getElementById("delivery_address1");
  const address2Field = document.getElementById("delivery_address2");
  const postalCodeField = document.getElementById("delivery_postcode");
  const suburbField = document.getElementById("delivery_suburb");
  const cityField = document.getElementById("delivery_city");
  const provinceField = document.getElementById("delivery_province");

  addStoreTypeListener(use_business_address_true);
  addStoreTypeListener(use_business_address_false);
  initAutocomplete(address1Field, address2Field, postalCodeField, suburbField, cityField, provinceField);

  if (use_business_address_false && use_business_address_false.checked) {
    showAddressSection()
  }


  function showAddressSection() {
    // Show container when delivery address checkbox is checked
    if (use_business_address_false.checked === true) {
      addressContainer.classList.remove('tw-hidden');
      addressContainer.classList.add('tw-flex');

      if (!address1Field.value) {
        // Only focus when delivery address has no value
        address1Field.focus();
      }
    } else {
      clearDeliveryAddressFields();
      addressContainer.classList.remove('tw-flex');
      addressContainer.classList.add('tw-hidden');
    }
  }

  function addStoreTypeListener(input) {
    if (input) {
      input.addEventListener('change', (event) => {
        if (use_business_address_false.checked === true) {
          clearDeliveryAddressFields();
        }

        showAddressSection();
      });
    }
  }

  function clearDeliveryAddressFields() {
    // Clear input values
    address1Field.value = "";
    address2Field.value = "";
    postalCodeField.value = "";
    suburbField.value = "";
    cityField.value = "";
    provinceField.value = "";

    // Clear html attribute
    address1Field.setAttribute("value", "");
    address2Field.setAttribute("value", "");
    postalCodeField.setAttribute("value", "");
    suburbField.setAttribute("value", "");
    cityField.setAttribute("value", "");
    provinceField.setAttribute("value", "");

    toggleSelectPlaceholderState();
  }
});
